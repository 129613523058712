import axios from 'axios';

export async function generateTimeSlots(payload) {
    const url = '/admin/appointments/time-slots';
    try {
      const response = await axios({
        method: 'post',
        url,
        data: payload
      });
      return response.data;
    } catch (error) {
      return error.response?.data || error.message;
    }
  }

  export async function getAvailableTimeSlots() {
    const url = '/admin/appointments/time-slots';
    try {
      const response = await axios({
        method: 'get',
        url
      });
      return response.data;
    } catch (error) {
      return error.response?.data || error.message;
    }
  }
